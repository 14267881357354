<template>
  <base-form
    model="meal"
    @save="meal_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.meal.loading"
    :currentValue="meal"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { ucFirst } from "../../apollo/helpers";

const MEAL_FORM_FRAGMENT = gql`
  fragment mealForm on Meal {
    id
    description
    manually_signed
    verified
    user {
      id
      complete_name
      residence {
        id
        name
      }
    }
    updated_at
  }
`;

export default {
  components: { BaseForm },
  name: "MealForm",
  props: {
    meal_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    meal_id(val) {
      if (val == null) this.meal = {};
    },
  },

  apollo: {
    meal: {
      query: gql`
        query Meal($id: ID!) {
          meal(id: $id) {
            ...mealForm
          }
        }
        ${MEAL_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.meal_id };
      },
      skip() {
        return this.meal_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      meal: {},
      saving: false,
      fields: [
        {
          name: "user",
          type: "query",
          path: "area.users",
          label: ucFirst(this.$tc("models.user", 1)),
          text: "complete_name",
          isVisible: (meal) => !meal.description,
          query: gql`
            query AreaUsers($id: ID!, $mealsAllowed: Boolean) {
              area(id: $id) {
                id
                users(mealsAllowed: $mealsAllowed) {
                  id
                  complete_name
                  residence {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: () => {
            return { id: this.baseItem.residence.area.id, mealsAllowed: true };
          },
          validation: {
            required,
          },
          bind: {
            clearable: true,
          },
        },
        {
          name: "description",
          validation: {
            required,
          },
          bind: {
            clearable: true,
          },

          isVisible: (meal) => !meal.user,
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Meal",
            fragment: MEAL_FORM_FRAGMENT,
            fragment_name: "mealForm",
          }),
          variables: {
            id: this.meal_id,
            input: this.newValue,
          },
        })
        .then(({ data: { updateMeal } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save", updateMeal);
        })
        .catch((error) => {
          this.$emit("error", error);
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Meal",
            fragment: MEAL_FORM_FRAGMENT,
            fragment_name: "mealForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createMeal } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createMeal);
        })
        .catch((error) => {
          this.$emit("error", error);
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
