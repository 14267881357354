var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"4","cols":"12"}},[(!_vm.loading)?_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-select',{attrs:{"items":_vm.managedResidences,"item-text":"name","item-value":"id","return-object":"","label":_vm.$t('residence.name')},model:{value:(_vm.selectedResidence),callback:function ($$v) {_vm.selectedResidence=$$v},expression:"selectedResidence"}})],1),(_vm.$route.params.residence_id)?_c('v-card-text',{staticClass:"text-center"},[(!_vm.loadingMeals)?_c('v-img',{attrs:{"src":_vm.currentUrl}}):_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_vm._e()],1):_c('v-skeleton-loader',{attrs:{"type":"card","rounded":"lg"}}),_c('br'),(_vm.selectedResidence && _vm.selectedResidence.canCreateNonCurrentMeal)?_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-text',{staticClass:"text-center"},[(_vm.selectedResidence)?_c('v-date-picker',{attrs:{"value":_vm.$route.params.date || _vm.selectedResidence.currentMeal.date,"full-width":"","show-adjacent-months":"","color":"primary","locale":"it","first-day-of-week":"1"},on:{"change":(date) => _vm.pushMeal({ date })}}):_vm._e(),(_vm.selectedResidence)?_c('v-radio-group',{attrs:{"row":"","value":_vm.$route.params.type || _vm.selectedResidence.currentMeal.type},on:{"change":(type) => _vm.pushMeal({ type })}},_vm._l((_vm.mealTypes),function(meal_type){return _c('v-radio',{key:meal_type,attrs:{"label":_vm.$t(`meal.type_${meal_type}`),"value":meal_type}})}),1):_vm._e(),(_vm.selectedResidence)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.$route.name === 'ResidenceMeals'},on:{"click":function($event){return _vm.$router.push({
                name: 'ResidenceMeals',
                params: { residence_id: _vm.$route.params.residence_id },
              })}}},[_vm._v(" "+_vm._s(_vm.$t("meal.return_today")))]):_vm._e()],1)],1):_vm._e()],1),(_vm.selectedResidence)?_c('v-col',{attrs:{"sm":"6","md":"8","cols":"12"}},[_c('v-card',{staticClass:"overflow-hidden",attrs:{"rounded":"lg"}},[(!_vm.loadingMeals)?_c('crud-table',{attrs:{"model":"meal","items":_vm.residence.meals.filter((meal) => meal.verified),"loading":_vm.loadingMeals,"title":_vm.title,"canEdit":false,"viewOnly":!_vm.residence.canManageMeals,"tableProps":{ 'custom-filter': _vm.filter },"showTotal":""},on:{"deleteItem":_vm.deleteMeal},scopedSlots:_vm._u([{key:`user.name`,fn:function({ item: { description, user } }){return [(user)?_c('router-link',{attrs:{"to":{ name: 'UserProfile', params: { id: user.id } }}},[_vm._v(_vm._s(user.complete_name)+" ")]):_c('span',[_vm._v(_vm._s(description))])]}},{key:`sign_mode`,fn:function({ item: { manually_signed, description } }){return [(description)?_c('v-chip',{attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t("options.meal.sign_mode.guest"))+" ")]):(manually_signed)?_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("options.meal.sign_mode.manual"))+" ")]):_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("options.meal.sign_mode.qr"))+" ")])]}},{key:"form",fn:function({ selected_id, close }){return [_c('meal-form',{attrs:{"meal_id":selected_id,"baseItem":{
                residence: _vm.selectedResidence,
                type: _vm.$route.params.type || _vm.selectedResidence.currentMeal.type,
                date: _vm.$route.params.date || _vm.selectedResidence.currentMeal.date,
              }},on:{"created":(newElement) => _vm.mealCreated(newElement, close),"cancel":close}})]}}],null,true)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"dark":"","color":"warning","top":"","timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(_vm._s(_vm.$t("enable")))])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("no_sign_sound")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }