<template>
  <v-container>
    <v-row>
      <v-col sm="6" md="4" cols="12">
        <v-card v-if="!loading" rounded="lg">
          <v-card-title>
            <v-select
              :items="managedResidences"
              item-text="name"
              item-value="id"
              v-model="selectedResidence"
              return-object
              :label="$t('residence.name')"
            ></v-select>
          </v-card-title>
          <v-card-text v-if="$route.params.residence_id" class="text-center">
            <v-img v-if="!loadingMeals" :src="currentUrl" />
            <v-skeleton-loader type="image" v-else />
          </v-card-text>
        </v-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
        <br />
        <v-card v-if="selectedResidence && selectedResidence.canCreateNonCurrentMeal" rounded="lg">
          <v-card-text class="text-center">
            <v-date-picker
              v-if="selectedResidence"
              :value="$route.params.date || selectedResidence.currentMeal.date"
              full-width
              show-adjacent-months
              @change="(date) => pushMeal({ date })"
              color="primary"
              locale="it"
              first-day-of-week="1"
            />
            <v-radio-group
              v-if="selectedResidence"
              @change="(type) => pushMeal({ type })"
              row
              :value="$route.params.type || selectedResidence.currentMeal.type"
            >
              <v-radio v-for="meal_type in mealTypes" :key="meal_type" :label="$t(`meal.type_${meal_type}`)" :value="meal_type"></v-radio>
            </v-radio-group>
            <v-btn
              v-if="selectedResidence"
              color="primary"
              :disabled="$route.name === 'ResidenceMeals'"
              @click="
                $router.push({
                  name: 'ResidenceMeals',
                  params: { residence_id: $route.params.residence_id },
                })
              "
            >
              {{ $t("meal.return_today") }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="selectedResidence" sm="6" md="8" cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <crud-table
            model="meal"
            :items="residence.meals.filter((meal) => meal.verified)"
            :loading="loadingMeals"
            :title="title"
            :canEdit="false"
            :viewOnly="!residence.canManageMeals"
            :tableProps="{ 'custom-filter': filter }"
            showTotal
            v-if="!loadingMeals"
            @deleteItem="deleteMeal"
          >
            <template v-slot:[`user.name`]="{ item: { description, user } }">
              <router-link v-if="user" :to="{ name: 'UserProfile', params: { id: user.id } }">{{ user.complete_name }} </router-link>
              <span v-else>{{ description }}</span>
            </template>

            <template v-slot:[`sign_mode`]="{ item: { manually_signed, description } }">
              <v-chip color="accent" v-if="description">
                {{ $t("options.meal.sign_mode.guest") }}
              </v-chip>
              <v-chip color="warning" v-else-if="manually_signed">
                {{ $t("options.meal.sign_mode.manual") }}
              </v-chip>
              <v-chip color="success" v-else>
                {{ $t("options.meal.sign_mode.qr") }}
              </v-chip>
            </template>

            <template v-slot:form="{ selected_id, close }">
              <meal-form
                :meal_id="selected_id"
                :baseItem="{
                  residence: selectedResidence,
                  type: $route.params.type || selectedResidence.currentMeal.type,
                  date: $route.params.date || selectedResidence.currentMeal.date,
                }"
                @created="(newElement) => mealCreated(newElement, close)"
                @cancel="close"
              />
            </template>
          </crud-table>
          <v-skeleton-loader type="table" v-else />
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar dark color="warning" top :timeout="-1" v-model="snackbar">
      <h4>{{ $t("no_sign_sound") }}</h4>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">{{ $t("enable") }}</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { addToCacheArray, deleteModel } from "../apollo/helpers";

import { playSound } from "../maia/utils.js";
import MealForm from "../components/forms/MealForm.vue";
import CrudTable from "../components/base/CrudTable.vue";
import { MANAGED_RESIDENCES_MEALS, RESIDENCE_MEALS } from "../graphql/queries";
import gql from "graphql-tag";
import fragments from "../graphql/fragments";
import moment from "moment";

const MEAL_SIGN_SUBSCRIPTION = gql`
  subscription NewMeal($input: NewMealInput!) {
    newMeal(input: $input) {
      ...meal
      type
      date
      qr_url
      residence {
        id
      }
    }
  }
  ${fragments.meal}
`;

export default {
  components: { CrudTable, MealForm },

  name: "Meals",
  data: () => ({
    event_id: 1,
    currentMeal: null,
    mealTypes: ["B", "L", "D"],
    lastInsertedId: -1,
    snackbar: false,
  }),

  computed: {
    title() {
      const { date, type } = this.query_variables;
      return `${this.$tc("models.meal", 2)} - ${moment(date).format("l")} - ${this.$t(`options.meal.type.${type}`)}`;
    },
    loading() {
      return this.$apollo.queries.managedResidences.loading;
    },
    loadingMeals() {
      return this.$apollo.queries.residence.loading;
    },
    currentUrl() {
      return this.currentMeal?.qr_url || this.residence?.meal_qr_url;
    },
    selectedResidence: {
      get() {
        return this.managedResidences?.find((residence) => residence.id === this.$route.params.residence_id);
      },
      set(residence) {
        if (residence.id != this.$route.params.residence_id) {
          let params = { ...this.$route.params, residence_id: residence.id };
          let name = this.selectedResidence ? this.$route.name : "ResidenceMeals";
          this.$router.push({ name, params });
        }
      },
    },
    query_variables() {
      return {
        residence_id: this.$route.params.residence_id,
        type: this.$route.params.type || this.selectedResidence.currentMeal.type,
        date: this.$route.params.date || this.selectedResidence.currentMeal.date,
      };
    },
  },

  watch: {
    selectedResidence() {
      this.currentMeal = null;
    },
  },

  methods: {
    pushMeal(newData) {
      if (this.selectedResidence) {
        this.$router.push({
          name: "ResidenceDateTypeMeals",
          params: {
            date: this.$route.params.date || this.selectedResidence.currentMeal.date,
            type: this.$route.params.type || this.selectedResidence.currentMeal.type,
            ...newData,
          },
        });
      }
    },

    mealCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: RESIDENCE_MEALS,
          variables: this.query_variables,
        },
        queryField: "residence.meals",
        newElement,
        unshift: true,
      });
      playSound();
      close();
    },

    deleteMeal({ id }) {
      deleteModel(this.$apollo, {
        id,
        query: { query: RESIDENCE_MEALS, variables: this.query_variables },
        queryField: "residence.meals",
        model: "Meal",
      });
    },

    filter(_, search, { user: { complete_name } }) {
      return complete_name.toLowerCase().includes(search.toLowerCase());
    },
  },

  apollo: {
    managedResidences: {
      query: MANAGED_RESIDENCES_MEALS,
      pollInterval: 15 * 60 * 1000 /*Poll every 15 minutes*/,
      update: (data) => data.me.managedResidencesMeals,
    },
    residence: {
      query: RESIDENCE_MEALS,
      variables() {
        return this.query_variables;
      },
      skip() {
        return this.selectedResidence == null;
      },
      subscribeToMore: {
        document: MEAL_SIGN_SUBSCRIPTION,
        variables() {
          return {
            input: this.query_variables,
          };
        },
        // Mutate the previous result
        updateQuery(previousResult, { subscriptionData }) {
          const {
            data: { newMeal },
          } = subscriptionData;

          if (!newMeal) return;

          let timeout = null;
          if (!newMeal.verified) {
            this.currentMeal = newMeal;
            timeout = setTimeout(() => (this.currentMeal = null), 10000);
          } else {
            if (newMeal.id === this.currentMeal?.id) {
              if (timeout) clearTimeout(timeout);
              this.currentMeal = null;
            }

            if (!previousResult.residence.meals.find((meal) => meal.id === newMeal.id)) {
              previousResult.residence.meals.unshift(newMeal);
              playSound().catch(() => {
                this.snackbar = true;
              });
            }
          }
          return previousResult;
        },
        skip() {
          return this.selectedResidence == null;
        },
      },
    },
  },
};
</script>
