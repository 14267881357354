import gql from "graphql-tag";
import fragments from "./fragments";

export const ME = gql`
  query Me {
    me {
      ...user
    }
  }
  ${fragments.user}
`;

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...user
    }
  }
  ${fragments.user}
`;

export const USERS = gql`
  query Users {
    users {
      id
      username
      name
      surname
      email
      phone
      type
      residence {
        id
        name
      }
    }
  }
`;

export const TAGS = gql`
  query Tags {
    me {
      id
      client {
        id
        tags {
          ...tag
        }
      }
    }
  }
  ${fragments.tag}
`;

export const EVENT_PRESENTS = gql`
  query EventPresents($event_id: ID!) {
    event(id: $event_id) {
      presents {
        id
        pivot {
          verified
        }
      }
    }
  }
`;

export const AREA_EVENTS = gql`
  query AreaEvents($area_id: ID!, $range: DateRange!) {
    area(id: $area_id) {
      events(range: $range) {
        ...short_event
      }
    }
  }
  ${fragments.short_event}
`;

export const MANAGED_RESIDENCES_MEALS = gql`
  query ManagedResidencesMeals {
    me {
      id
      managedResidencesMeals {
        id
        name
        canCreateNonCurrentMeal
        currentMeal {
          date
          type
        }
        area {
          id
        }
      }
    }
  }
`;

export const MANAGED_USERS_MEALS = gql`
  query ManagedResidencesMeals {
    me {
      id
      managedResidencesMeals {
        id
        users {
          id
          complete_name
        }
      }
    }
  }
`;

export const RESIDENCE_MEALS = gql`
  query ResidenceMeals($residence_id: ID!, $type: String!, $date: Date!) {
    residence(id: $residence_id) {
      id
      canManageMeals
      meal_qr_url(mealInfo: { type: $type, date: $date })
      meals(date: $date, type: $type) {
        ...meal
      }
    }
  }
  ${fragments.meal}
`;

export const RESIDENCE_USERS = gql`
  query ResidenceUsers($residence_id: ID!) {
    residence(id: $residence_id) {
      id
      name
      users {
        ...user
      }
    }
  }
  ${fragments.user}
`;

export const STUDENT_INFO = gql`
  query StudentInfo($id: ID!) {
    student(id: $id) {
      ...student

      degrees {
        ...degree
      }

      careers {
        ...career
      }

      studentYears {
        id
        year {
          id
          name
        }
      }
    }
  }
  ${fragments.student}
  ${fragments.career}
  ${fragments.degree}
`;

export const USER_PROFILE_INFO = gql`
  query UserProfileInfo($id: ID!) {
    user(id: $id) {
      ...user
      student {
        id
      }
    }
  }
  ${fragments.user}
`;
